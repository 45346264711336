import React, { useState, useEffect } from 'react';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout/Layout';
import ReactPlayer from 'react-player';
import BlockContent from '@sanity/block-content-to-react';

const Project = styled.main`
	padding: 20px;
`;

const Header = styled.header`
	position: sticky;
	top: 0;
	padding: 20px;
	display: flex;
	justify-content: space-between;
`;

const HeaderTitle = styled.h3`
	text-transform: uppercase;

	@media (min-width: 1024px) {
		font-size: 18px;
	}
`;

const HeaderLink = styled(Link)`
	color: inherit;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}

	@media (min-width: 1024px) {
		font-size: 18px;
	}
`;

const HeroSection = styled.section``;

const PlayerWrapper = styled.div`
	position: relative;
	padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

const Player = styled.video`
	max-width: 100%;
	width: 100%;

	&:focus {
		outline: 1px solid transparent;
	}
`;

const ProjectDescription = styled.section``;

const Description = styled.aside``;

const Credits = styled.aside``;

const MoreAssets = styled.section``;

const ArchiveProject = ({ pageContext }) => {
	return (
		<Layout>
			<SEO title={pageContext.title} />

			<Header>
				<HeaderTitle>{pageContext.title}</HeaderTitle>
				<HeaderLink to="/archive">Back</HeaderLink>
			</Header>

			<Project>
				<HeroSection>
					<Player
						src={pageContext.hero.asset.url}
						type="video/mp4"
						autoPlay={false}
						muted={false}
						loop={false}
						playsInline
						controls={true}
					/>
				</HeroSection>

				{(pageContext._rawDescription || pageContext.credits.length > 0) && (
					<ProjectDescription>
						{pageContext._rawDescription && (
							<Description>
								<BlockContent />
							</Description>
						)}
						{pageContext.credits.length > 0 && <Credits></Credits>}
					</ProjectDescription>
				)}

				{pageContext.projectAssets.length > 0 && <MoreAssets></MoreAssets>}
			</Project>
		</Layout>
	);
};
export default ArchiveProject;
